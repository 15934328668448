import { BigNumber, providers } from 'ethers';

/**
 * Interfaces
 */
export interface Chain<Type = Object>
{
    chainId: ChainId;
    blockchain: Blockchain;
    isMainnet: boolean;
    displayName: string;
    shortName: string;
    icon: string;
}

export interface IProvidersConfig
{
    ALCHEMY_FRONTEND_URLS: Record<any, string[]>;
    BSC_PROVIDER_URLS: Record<any, string[]>;
    HARDHAT_PROVIDER_URLS: Record<any, string[]>;
    FANTOM_PROVIDER_URLS: Record<any, string[]>;
    POLYGON_PROVIDER_URLS: Record<any, string[]>;
    WALLETCONNECT_RPC: Record<any, string>;
    ETHERSCAN_PROVIDER_ID: string;
    AVALANCHE?: Record<any, any>;
    GSN_CONFIG?: Record<any, any>;
}

export interface ITransactionData
{
    from?: any;
    to?: any;
    data?: any;
    gas?: number;
    gasPrice?: any;
    value?: string | BigNumber;
    nonce?: any;
    chainId?: ChainId;
    gasLimit?: any;
}

/**
 * enums
 */

export enum ChainId
{
    ETHEREUM_MAINNET = 1,
    ETHEREUM_ROPSTEN = 3,
    ETHEREUM_GOERLI = 5,
    ETHEREUM_SEPOLIA = 11155111,
    ETHEREUM_KOVAN = 42,
    BSC_MAINNET = 56,
    BSC_TESTNET = 97,
    POLYGON_MAINNET = 137,
    FANTOM_MAINNET = 250,
    FANTOM_TESTNET = 4002,
    HARDHAT_TESTNET = 31337,
    AVALANCHE_MAINNET = 43114,
    AVALANCHE_TESTNET = 43113,
    ARBITRUM_MAINNET = 42161,
    POLYGON_AMOY = 80002,
    BASE_MAINNET = 8453,
    TON_MAINNET = -239,
    TON_TESTNET = -3,
    SOLANA_MAINNET = 101,
    SEI_MAINNET = 1329
}


export enum Blockchain
{
    ETHEREUM = 0,
    BINANCE_SMART_CHAIN = 1,
    SOLANA = 2,
    POLYGON = 3,
    AVALANCHE = 4,
    FANTOM = 5,
    HARDHAT = 6,
    BASE = 7,
    ARBITRUM = 8,
    SEI = 9,
    TON = -5000,
}

export enum eCyberWalletEvent
{
    SEND_TRANSACTION = 'SEND_TRANSACTION',
    TRANSATION_RESULT = 'TRANSATION_RESULT',
    GET_WALLET_ADDRESS = 'GET_WALLET_ADDRESS',
    WALLET_ADDRESS = 'WALLET_ADDRESS',
    GET_HASH = 'GET_HASH',
    HASH_RESULT = 'HASH_RESULT',
    SIGN_MSG = 'SIGN_MSG',
    SIGN_MSG_RESULT = 'SIGN_MSG_RESULT',
    CALL_METHOD = 'CALL_METHOD',
    CALL_METHOD_RESULT = 'CALL_METHOD_RESULT'
}

/**
 * types
 */

export type EthersProvider =
    | providers.JsonRpcProvider
    | providers.FallbackProvider
    | providers.Web3Provider;

export type Connector =
    | 'metamask'
    | 'cryptocom'
    | 'bitkeep'
    | 'walletconnect'
    | 'web3'
    | 'cyberwallet'
    | 'mock-evm'
    | 'solana';


export type IScanModule =
    'account' |
    'contract' |
    'transaction' |
    'block' |
    'logs' |
    'proxy' |
    'stats' |
    'token' |
    'gastracker';

export interface IDistributionInfo
{
    total: number | undefined;
    distributed: number | undefined;
    notDistributed: number | undefined;
}